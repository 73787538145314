.caption p {
    text-align: justify;
}

@media (max-width: 1200px) and (min-width: 979px) {
    .order-description {
        height: 40px;
    }
}

@media (min-width: 924px){
    .order-description {
        position: relative;
        height: 81px;
    }
}


@media (min-width: 768px) and (max-width: 979px) {
    .order-description {
        height: 140px;
    }
}

@media (max-width: 767px) {
    .order-paragraph {
        text-align: center;
    }
}

@media (max-width: 1200px) {
    .order-title {
        height: 80px;
    }
    .order-description {
        position: relative;
        height: 100px;
    }
}
