
.text-block {
    position: relative;
    height: 343px;
}

@media (max-width: 1200px) and (min-width: 980px) {
    .text-block {
        position: relative;
        height: 380px;
    }
}

@media (max-width: 979px) {
    .text-block {
        position: relative;
        height: 420px;
    }
}

@media (max-width: 768px) {
    .text-block {
        height: auto;
    }
}